<template>
  <div class="">
    <a @click="$router.push({ name: route })">
      <div class="card-relacionado">
        <div
          class="ribbonRel ribbon-top-right"
          :style="'border-color:'+ imgIcon"
        >
          <span>{{ relevance }}</span>
        </div>
        <p>
          <span class="title-h2" style="text-align: justify"
            ><strong>{{ title }}</strong>
          </span>
          <strong>{{ subtitle }}</strong>
        </p>
        <br />
        <p>
          <span><strong>Palavras-chave: </strong></span>
          {{ keywords }}
        </p>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    imgIcon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    relevance: {
      type: Number,
      required: true,
    },
    keywords: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="css" src="./index.css" scoped />
